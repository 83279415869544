<template>
  <div class="wrapper">
    <div class="display-f align-items-c justify-end">
      <span>消息类型</span>
      <div class="margin-l20">
        <a-select v-model="searchForm.noticeType" placeholder="请选择消息类型" @change="getListByType" style="width: 200px">
          <a-select-option :value="null">全部</a-select-option>
          <a-select-option :value="1">重要通知</a-select-option>
          <a-select-option :value="2">通知</a-select-option>
          <a-select-option :value="3">模版消息</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="margin-t30">
      <template v-if="complete">
        <template v-if="list && list.length">
          <div class="notice-item" v-for="(item, index) in list" :key="item.id" @click="setRead(item)">
            <div class="left">
              <div class="position-r">
                <img src="@/assets/image/notice/notice_warn.png" alt="" v-if="item.noticeType === '1'">
                <img src="@/assets/image/notice/notice.png" alt="" v-else>
                <span class="red-point" v-if="!item.readFlag"></span>
              </div>
            </div>
            <div class="right">
              <div class="flex1" style="width: 0">
                <div class="font-s18 font-wb">{{ item.noticeTitle }}</div>
                <div class="margin-t10 omit2">{{ item.noticeContent }}</div>
              </div>
              <div>
                <div>{{ item.createTime }}</div>
              </div>
            </div>
          </div>
          <div class="margin-t20 display-f justify-end">
            <a-pagination
                size="small"
                :total="total"
                show-size-changer
                show-quick-jumper
                :show-total="total => `共 ${total} 条`"
                :current="searchForm.pageNum"
                @showSizeChange="pageChange"
                @change="pageChange"
            />
          </div>
        </template>
        <template v-else>
          <a-empty />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getMessageList, setNoticeRead } from '../../api/message/message'

export default {
  name: 'message',
  data () {
    return {
      searchForm: {
        noticeType: null,
        pageNum: 1,
        pageSize: 10
      },
      page: 1,
      list: [],
      total: 0,
      complete: false
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getListByType () {
      this.searchForm.pageNum = 1
      this.searchForm.pageSize = 10
      this.getList()
    },
    getList() {
      getMessageList(this.searchForm).then(res => {
        this.list = res.data.list
        this.total = res.data.total
        this.complete = true
      })
    },
    pageChange (current, pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = current
      this.getList()
    },
    setRead (item) {
      setNoticeRead({
        noticeId: item.id
      }).then(() => {
        item.readFlag = true
      })
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
  },
}
</script>

<style scoped lang="scss">
  .notice-item {
    background: $white;
    border: 0;
    border-radius: 10px;
    height: 110px;
    display: flex;
    margin-bottom: 15px;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px dashed $border-c;
      width: 130px;
      position: relative;
    }
    .right {
      flex: 1;
      display:  flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px
    }
  }
</style>
